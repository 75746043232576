import React from "react"
import { GridContainer } from "pages/search"
import { ProductItem } from "components/ProductItem/ProductItem"
import { useProductList } from "hooks/useProductList"
import { graphql, useStaticQuery } from "gatsby"
import { Query } from "typings/graphql"

const query = graphql`
  query {
    sanityGlobalSettings {
      searchProductRecommendations {
        ...Product
      }
    }
  }
`

export const EmptySearch = () => {
  const { sanityGlobalSettings } = useStaticQuery<Query>(query)
  const searchProductRecommendations =
    sanityGlobalSettings?.searchProductRecommendations
  const { plItems, loading } = useProductList(searchProductRecommendations)
  return !!plItems.length && !loading ? (
    <GridContainer>
      {plItems.slice(0, 4).map((item, index) => (
        <ProductItem
          key={item.product?.id}
          linkMode="link"
          item={item}
          mode="dark"
          position={index}
        />
      ))}
    </GridContainer>
  ) : null
}
