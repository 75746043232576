import { MutableRefObject } from "react"

/**
 * This is a workaround so that we can set the value of the input
 * via a ref and trigger a React event.
 *
 * Setting the value with "inputRef.current.value = 'adsfasd'"
 * does not trigger a React event.
 *
 * Code from https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
 */
export const setInputValue = (
  inputRef: MutableRefObject<HTMLInputElement>,
  newValue: string
) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set

  nativeInputValueSetter.call(inputRef.current, newValue)

  const event = new Event("input", { bubbles: true })

  inputRef.current.dispatchEvent(event)
}
