import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Query, SanityProduct } from "typings/graphql"
import { App } from "components/App/App"
import { setTheme } from "helpers/Theme"
import { Spinner } from "components/UI/Spinner"
import { SearchInput } from "components/Search/SearchInput/SearchInput"
import { ProductItem } from "components/ProductItem/ProductItem"
import { useProductList } from "hooks/useProductList"
import { useLocation } from "react-use"
import styled from "styled-components"
import { mq, typography } from "styles"
import algoliasearch from "algoliasearch"
import { algoliaProductIndexName } from "config/searchConfig"
import { tracking } from "utils/tracking"
import { setInputValue } from "utils/reactUtils"
import { decodeShopifyId } from "utils/stringUtils"
import { useSelector } from "react-redux"
import { RootState } from "redux/rootReducer"
import { EmptySearch } from "components/Search/EmptySearch/EmptySearch"

const path = "/search"
const mode = "dark"

export const Container = styled.div`
  min-height: 100vh;
  padding: 32px 30px 64px 30px;

  ${mq.minWidth("sm")} {
    padding: 48px 48px 80px 48px;
  }

  ${mq.minWidth("lg")} {
    padding: 48px 60px 96px 60px;
  }

  display: flex;
  justify-content: start;
  background: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`
export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`
export const SearchResultsHeader = styled.h3`
  color: var(--text-headline-dark);
  margin-top: 64px;
  margin-bottom: 8px;
`

export const SearchResultsCount = styled.div`
  margin-bottom: 32px;
  ${typography.body};

  ${mq.minWidth("md")} {
    margin-bottom: 48px;
  }
`

export const GridContainer = styled.div`
  display: grid;
  width: 100%;

  grid-row-gap: 48px;
  grid-column-gap: 48px;
  grid-template-columns: 1fr;

  ${mq.minWidth("md")} {
    grid-row-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }

  ${mq.minWidth("lg")} {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 64px;
    grid-column-gap: 40px;
  }
`

const SearchBox = styled(SearchInput)`
  max-width: 660px;
`

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const productsIndex = algoliaClient.initIndex(algoliaProductIndexName)

type PageProps = {
  data: Query
}

const SearchResultsPage = ({ data }: PageProps): React.ReactElement => {
  const [searchResults, setSearchResults] = React.useState<SanityProduct[]>()
  const [algoliaQueryId, setAlgoliaQueryId] = React.useState<string>(null)
  const { plItems, loading } = useProductList(searchResults)
  const inputRef = React.useRef<HTMLInputElement>()
  const location = useLocation()
  const getSearchQueryParam = () => {
    return new URLSearchParams(location.search).get("q")
  }

  const [searchTerm, setSearchTerm] = React.useState(getSearchQueryParam())

  const theme = setTheme(
    data.sanityTypography,
    data.sanityDarkTheme,
    data.sanityLightTheme,
    data.sanityGlobalTheme
  )

  const seoData = {
    seo: data.allSanityPage?.nodes[0]?.seo,
    path,
  }

  useEffect(() => {
    const searchQueryParam = getSearchQueryParam()

    setInputValue(inputRef, searchQueryParam)
    setSearchTerm(searchQueryParam)
  }, [location.search])

  useEffect(() => {
    setSearchResults(null)
    setAlgoliaQueryId(null)

    productsIndex
      .search(searchTerm, { clickAnalytics: true })
      .then(({ queryID, hits }) => {
        setAlgoliaQueryId(queryID)
        const products = hits.map(hit =>
          data.allSanityProduct.nodes.find(
            product =>
              decodeShopifyId(product?.shopifyProduct?.shopifyId) ===
              hit.objectID
          )
        )
        setSearchResults(products)
      })
  }, [searchTerm])

  const updateSearch = (searchTerm: string) => {
    const searchParam = !!searchTerm ? `?q=${searchTerm}` : ""

    setSearchTerm(searchTerm)
    window.history.pushState(
      "",
      "",
      `${window.location.pathname}${searchParam}`
    )
  }

  const handleUserSearch = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter" && event.currentTarget.value?.length) {
      const searchQuery = event.currentTarget.value

      updateSearch(searchQuery)
      tracking.searched(searchQuery, "products")
    }
  }

  const onSearchInputXClicked = () => {
    setInputValue(inputRef, "")
  }

  return (
    <App theme={theme} layout="page" mode={mode} seo={seoData}>
      <Container>
        <div style={{ width: "100%" }}>
          <SearchBox
            onKeyDown={handleUserSearch}
            ref={inputRef}
            onXClicked={onSearchInputXClicked}
          />
          {!loading && searchTerm && algoliaQueryId && searchResults ? (
            <div>
              <SearchResultsHeader>{`Search results for "${searchTerm}"`}</SearchResultsHeader>
              <SearchResultsCount>
                {searchResults.length
                  ? `${searchResults.length} Products`
                  : `0 results found. Showing popular products:`}
              </SearchResultsCount>
              {searchResults.length > 0 && (
                <GridContainer>
                  {plItems?.map((productItem, index) => (
                    <ProductItem
                      key={productItem.product.id}
                      linkMode="link"
                      item={productItem}
                      mode={mode}
                      position={index}
                      algoliaQueryId={algoliaQueryId}
                    />
                  ))}
                </GridContainer>
              )}
              {!searchResults.length && <EmptySearch />}
            </div>
          ) : (
            searchTerm &&
            (loading || !searchResults) && (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )
          )}
        </div>
      </Container>
    </App>
  )
}

export const pageQuery = graphql`
  query SearchResultsPage {
    sanityTypography {
      ...Typography
    }
    sanityDarkTheme {
      ...DarkTheme
    }
    sanityLightTheme {
      ...LightTheme
    }
    sanityGlobalTheme {
      ...GlobalTheme
    }
    allSanityProduct {
      nodes {
        ...ProductLite
      }
    }
    allSanityPage(filter: { path: { eq: "/search" } }) {
      nodes {
        path
        seo {
          ...SanitySeo
        }
      }
    }
  }
`

export default SearchResultsPage
